const state = {
    roles: [],
}

const getters = {
    rolesSortedByName(state) {
        return state.roles.sort((a, b) => a.name.localeCompare(b.name))
    },
}

const actions = {}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
